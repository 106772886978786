.footer{
    text-align: center;
    padding: 18px 0;
    margin-top: 30px;
    background-color: #fff;

    @media (max-width: 767px) {
        margin-top: 20px;
    }
    @media (max-width: 575px) {
        margin-top: 10px;
    }
    p{
        margin: 0;
        font-size: 14px;
        line-height: 14px;
    }
}