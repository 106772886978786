.management-screen {
    .main-title {
        padding: 30px 0;

        h1 {
            margin: 0;
            font-size: 30px;
            line-height: 1;
            font-weight: 600;
            color: #060A23;
            font-family: "Poppins", sans-serif;

            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
    }

    .content-section {
        display: flex;
        flex-direction: column;

        .tree-upload-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .tree-section {
                flex: 1;
                margin-right: 20px;
                background-color: #f0f0f0;
                padding: 10px;
                border-radius: 5px;
            }

            .upload-section {
                flex: 1;
                background-color: #f0f0f0;
                padding: 10px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                gap: 10px;

                .storage-manager {
                    flex-grow: 1;
                }

                .upload-controls {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .document-type {
                        position: relative;

                        .selection {
                            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                            height: 40px;
                            font-size: 15px;
                            line-height: 1.2;
                            width: 100%;
                            color: #060A23;
                            min-width: 250px;
                            border-radius: 5px;
                            padding: 5px 15px;
                            border: none;
                            appearance: none;
                        }

                        img {
                            position: absolute;
                            top: 20px;
                            right: 15px;
                            pointer-events: none;
                        }
                    }

                    .upload-button {
                        .btn {
                            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                            height: 40px;
                            font-size: 15px;
                            line-height: 1.2;
                            width: 100%;
                            color: #fff;
                            min-width: 190px;
                            border-radius: 5px;
                            background-color: #6936F5;

                            &:hover {
                                background: #fff;
                                color: #6936F5;
                                border: 1px solid #6936F5;
                            }
                        }
                    }
                }
            }
        }
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .absolute-selection {
            position: relative;

            .navigation-selection {
                position: absolute;
                top: -30px;

                @media (max-width: 500px) {
                    position: static;
                    margin-bottom: 30px;
                }

                .selection {
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                    height: 50px;
                    font-size: 15px;
                    line-height: 1.2;
                    width: 100%;
                    color: #060A23;
                    min-width: 250px;
                    border-radius: 5px;
                    padding: 5px 15px;
                    border: none;
                    appearance: none;
                }

                img {
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    pointer-events: none;
                }
            }
        }

        .upload-control {
            display: flex;
            align-items: center;
            gap: 10px;

            .document-type {
                position: relative;

                .selection {
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                    height: 50px;
                    font-size: 15px;
                    line-height: 1.2;
                    width: 100%;
                    color: #060A23;
                    min-width: 250px;
                    border-radius: 5px;
                    padding: 5px 15px;
                    border: none;
                    appearance: none;
                }

                img {
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    pointer-events: none;
                }
            }

            .upload-button {
                .btn {
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                    height: 50px;
                    font-size: 15px;
                    line-height: 1.2;
                    width: 100%;
                    color: #fff;
                    min-width: 190px;
                    border-radius: 5px;
                    background-color: #6936F5;

                    &:hover {
                        background: #fff;
                        color: #6936F5;
                        border: 1px solid #6936F5;
                    }
                }
            }
        }
    }

    .table-section {
        color: #656565;

        .action-buttons {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background: #EDEDED;
                border-radius: 100px;
                font-size: 14px;
                color: #D0C7C7;

                &.delete-btn {
                    background: #FBD8D8;
                    color: #F5334A;
                }

                &.download-btn {
                    background: #CFF7E9;
                    color: #2DDB9C;
                }
            }
        }

        span.file-icon {
            display: block;
            font-size: 30px;
            color: #D9D9D9;
        }

        .DataTable-class {
            border-radius: 15px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            padding: 0 20px 20px;
            background-color: #fff;

            .kUMlDB {
                border: 0;
                color: #656565;
            }

            .mHisf {
                border-color: #D9D9D9;
                color: #656565;
            }
        }
    }

    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; // Ensure the loader is centered within the viewport
    }
}
