.custom-pagination {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    button{
        width: 40px;
        height: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        background: #fff;
        border-radius: 100%;
        color: #000;
        margin: 0 5px;
        font-weight: 700;
        &.active{
            border: 1px solid #6936F5;
            background: #6936F5;
            color: #fff;
        }
        &:hover{
            border: 1px solid #6936F5;
            background: #6936F5;
            color: #fff;
        }
        &.arrows{
            background: #060A23;
            border-color: #060A23;
            color: #fff;
        }
    }
}