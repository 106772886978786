.header.navbar {
    background: #060A23;
    padding: 15px 0;
    height: 60px; // Set a fixed height for the header

    .navbar-brand {
        display: flex;
        align-items: center;
        color: #6936F5;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        
        .logo {
            height: 100%;
            max-height: 70px; // Adjust the max height as needed to fit within the header
            width: auto; // Maintain aspect ratio
        }

        
    }

    button.navbar-toggler {
        @media (max-width: 992px) {
            background: #fff;
            outline: none;
            box-shadow: none;
        }
    }

    .navbar-collapse {
        .navbar-nav {
            display: flex;
            align-items: center; // Ensure nav links are vertically centered
            
            @media (max-width: 992px) {
                margin-bottom: 40px;
            }

            .nav-link {
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.2;
                opacity: 0.5;
                margin: 0 40px;

                &.active {
                    opacity: 1;
                }

                @media (max-width: 1300px) {
                    padding: 0 20px;
                    margin: 0 10px;
                }

                @media (max-width: 1100px) {
                    padding: 0 10px;
                }

                @media (max-width: 992px) {
                    padding: 15px 5px;
                }
            }
        }

        .right-bar {
            height: 50px;

            @media (max-width: 992px) {
                height: auto;
            }

            .navbar-nav {
                @media (max-width: 992px) {
                    flex-direction: row;
                    margin-bottom: 0px;
                }

                .nav-link {
                    opacity: 1;
                    margin: 0;
                    padding: 0 20px;
                    font-size: 17px;
                }
            }

            .column {
                &:last-child {
                    border-left: 1px solid #ffffff33;

                    .navbar-nav {
                        .nav-link {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        .user-letter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            background-color: #6936F5; // Circle background color
            border-radius: 50%; // Make it a circle
            color: #fff; // Text color
            font-size: 18px; // Font size
            font-weight: bold; // Font weight
        }
    }
}
