
.wide-modal .modal-dialog {
    max-width: 80% !important; /* Ensure this takes precedence */
    width: 80% !important; /* Explicitly set the width */
}

.wide-modal .modal-body {
    overflow-x: auto; /* This ensures horizontal scrolling if content overflows */
}

.opportunity-screen{
    .main-title{
        padding: 30px 0;
        h1{
            margin: 0;
            font-size: 30px;
            line-height: 1;
            font-weight: 600;
            color: #060A23;
            font-family: "Poppins", sans-serif;

            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
    }
    .table-header{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        .upload-control{
            display: flex;
            align-items: center;
            gap: 10px;

            .upload-button {
                .btn {
                    box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                    height: 50px;
                    font-size: 15px;
                    line-height: 1.2;
                    width: 100%;
                    color: #fff;
                    min-width: 190px;
                    border-radius: 5px;
                    background-color: #6936F5;

                    &:hover {
                        background: #fff;
                        color: #6936F5;
                        border: 1px solid #6936F5;
                    }
                }
            }
        }
}
    .table-section{
        color: #656565;
        .progress {
            width: 100%; // Widen the progress bar to fill the available space
            height: 10px; // Make the progress bar thinner
            background-color: #e9ecef;
            border-radius: 5px;
            overflow: hidden;
            margin: 0; // Remove any margin if necessary

            .progress-bar {
                background-color: #28a745;
                height: 100%; // Ensure the progress bar fills the container's height
                line-height: 10px; // Align content inside the progress bar
                font-size: 0; // Hide the percentage text
            }
        }
        .action-buttons {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background: #EDEDED;
                border-radius: 100px;
                font-size: 14px;
                color: #D0C7C7;
                &.delete-btn{
                    background: #FBD8D8;
                    color: #F5334A;
                }
                &.download-btn{
                    background: #CFF7E9;
                    color: #2DDB9C;
                }
                &.graph-btn{
                    background: #CFF7E9;
                    color: #2DDB9C;
                }
                &.evaluate-btn{
                    background: #CFF7E9;
                    color: #2d78db;
                }
            }
        }
        span.file-icon {
            display: block;
            font-size: 30px;
            color: #D9D9D9;
        }
        .DataTable-class {
            border-radius: 15px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            padding: 0 20px 20px;
            background-color: #fff;
            .kUMlDB{
                border: 0;
                color: #656565;
            }
            .mHisf{
                border-color: #D9D9D9;
                color: #656565;
            }
        }
    }
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; // Ensure the loader is centered within the viewport
    }
    
}