.dashboard-screen{
    .main-title{
        padding: 30px 0;
        h1{
            margin: 0;
            font-size: 30px;
            line-height: 1;
            font-weight: 600;
            color: #060A23;
            font-family: "Poppins", sans-serif;

            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
    }
    .charts-section {
        .row {

            @media (max-width: 992px) {
                gap: 20px;
            }
            
            .chart-box {
                border-radius: 15px;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
                padding: 20px;
                background-color: #fff;

                .chart-container svg path {

                    svg {
                        path {
                            box-shadow: 0px 6px 15px 0px #17D7174D;
                        }
                    }
                }
                .loader-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh; // Ensure the loader is centered within the viewport
                }
            }
        }
    }
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;

        @media (max-width: 520px) {
            flex-wrap: wrap;
            gap: 20px;
        }
        .navigation-button{
            .btn{
                box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                height: 50px;
                font-size: 15px;
                line-height: 1.2;
                width: 100%;
                color: #060A23;
                min-width: 190px;
                border-radius: 5px;
                background-color: #fff;
                &:hover{
                    background: #060a23;
                    color: #fff;
                }
            }
        }
        .filter-search {
            width: 100%;
            max-width: 700px;
            position: relative;

            @media (max-width: 992px) {
                max-width: max-content;
            }

            @media (max-width: 520px) {
                max-width: 100%;
            }
            input {
                width: 100%;
                border: 0;
                box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
                height: 50px;
                color: #060A23;
                border-radius: 5px;
                font-size: 15px;
                line-height: 1.2;
                padding: 0 20px;
                padding-right: 70px;
                outline: none;
                &::placeholder{
                    color: #797979;
                }
            }
            .search-btn {
                position: absolute;
                right: 0;
                top: 0;
                border: 0;
                background: #fff;
                height: 50px;
                width: 50px;
                border-radius: 5px;
            }
        }
    }
    .table-section{
        color: #656565;
        .action-buttons-dash{
            display: flex;
            width: 100%;
            justify-content: flex-start; /* Change to flex-start to align items to the left */
            gap: 5px; /* Add gap to control the space between buttons */
            
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                background: #EDEDED;
                border-radius: 100px;
                font-size: 14px;
                color: #D0C7C7;
                margin: 0; /* Ensure no margins */
                padding: 0; /* Ensure no padding */
                // &.delete-btn{
                //     background: #FBD8D8;
                //     color: #F5334A;
                // }
                &.download-btn{
                    background: #CFF7E9;
                    color: #2DDB9C;
                }
                &.link-btn{
                    background: #CFF7E9;
                    color: #2d78db;
                }
            }
        }
        span.file-icon {
            display: block;
            font-size: 30px;
            color: #D9D9D9;
        }
        .DataTable-class {
            border-radius: 15px;
            box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
            padding: 0 20px 20px;
            background-color: #fff;

            .kUMlDB{
                border: 0;
                color: #656565;
            }
            .mHisf{
                border-color: #D9D9D9;
                color: #656565;
            }
        }
    }
}