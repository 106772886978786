@keyframes typingDots {
    0% {
        content: '.';
    }
    33% {
        content: '..';
    }
    66% {
        content: '...';
    }
    100% {
        content: '.';
    }
}

.typing {
    .user-message::after {
        content: '...';
        animation: typingDots 1s steps(3, end) infinite;
    }
}


.performance-screen{
    .main-title{
        padding: 30px 0;

        @media (max-width: 767px) {
            padding: 20px 0;
        }
        h1{
            margin: 0;
            font-size: 30px;
            line-height: 1;
            font-weight: 600;
            color: #060A23;
            font-family: "Poppins", sans-serif;

            @media (max-width: 767px) {
                font-size: 25px;
            }
        }
    }
    .chat-sec {
        .chat-container {
            max-width: 1020px;
            margin: auto;

            .chat-wrapper {
                padding: 50px 20px 0;

                @media (max-width: 1600px) {
                    padding: 20px 20px 10px;
                }

                @media (max-width: 992px) {
                    padding: 10px 20px 10px;
                }

                .chat-body {
                    display: flex;
                    flex-direction: column;

                    .chat-area {
                        display: block;
                        height: calc(100vh - 405px);
                        overflow: auto;

                        @media (max-width: 1600px) {
                            height: calc(100vh - 385px);
                        }

                        @media (max-width: 992px) {
                            height: calc(100vh - 335px);
                        }

                        @media (max-width: 767px) {
                            height: calc(100vh - 300px);
                        }

                        @media (max-width: 767px) {
                            height: calc(100vh - 290px);
                        }

                        &::-webkit-scrollbar-track
                        {
                            border-radius: 0px;
                            background-color: #F5F5F5;
                        }
                        
                        &::-webkit-scrollbar
                        {
                            width: 1px;
                            background-color: #F5F5F5;
                        }
                        
                        &::-webkit-scrollbar-thumb
                        {
                            border-radius: 0px;
                            background-color: #F4F7FC;
                        }

                        .sender {
                            display: flex;
                            margin-bottom: 8px;

                            @media (max-width: 767.5px) {
                                margin-bottom: 15px;
                                margin-right: 10px;
                            }

                            .user-img {
                                height: 35px;
                                min-height: 35px;
                                width: 35px;
                                min-width: 35px;
                                border-radius: 50%;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 50%;
                                }
                            }

                            .user-text {
                                display: flex;
                                flex-direction: column;
                                margin-left: 10px;
                                max-width: 665px;

                                h4.user-name {
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #060A23;
                                    font-weight: 600;
                                    margin-bottom: 10px;
                                }

                                .user-message-box {
                                    border-radius: 0px 30px 30px 30px;
                                    box-shadow: 0px 0px 15px 0px #0000001A;
                                    background-color: #fff;
                                    padding: 15px 40px 15px 20px;

                                    @media (max-width: 575px) {
                                        padding: 15px 25px 15px 20px;
                                    }

                                    p.user-message {
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #565656;
                                        font-weight: 400;
                                        margin-bottom: 0px;
                                    }

                                    ul.user-response-menu {
                                        padding-left: 0;
                                        list-style-type: none;
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 0;
                                        margin-top: 8px;

                                        li {
                                            margin-right: 12px;
                                            a {
                                                img {
                                                    height: auto;
                                                    width: auto;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .receiver {
                            display: flex;
                            flex-direction: row-reverse;
                            margin-bottom: 8px;

                            @media (max-width: 767.5px) {
                                margin-bottom: 15px;
                            }

                            .user-img {
                                height: 35px !important;
                                min-height: 35px !important;
                                width: 35px !important;
                                min-width: 35px !important;
                                border-radius: 50% !important;
                                display: flex !important;
                                align-items: center !important;
                                justify-content: center !important;
                                background-color: #D9D9D9 !important; // Example background color
                                color: #fff !important; // Example text color
                                font-size: 18px !important; // Example font size
                                font-weight: bold !important; // Example font weight
                            }

                            .user-letter {
                                display: flex !important;
                                align-items: center !important;
                                justify-content: center !important;
                                width: 100% !important;
                                height: 100% !important;
                                background-color: #6936F5 !important; // Circle background color
                                border-radius: 50% !important; // Make it a circle
                                color: #fff !important; // Text color
                                font-size: 18px !important; // Font size
                                font-weight: bold !important; // Font weight
                            }

                            .user-text {
                                display: flex;
                                flex-direction: column;
                                margin-right: 10px;
                                max-width: 515px;

                                h4.user-name {
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #060A23;
                                    font-weight: 600;
                                    margin-bottom: 10px;
                                    text-align: right;
                                }

                                .user-message-box {
                                    border-radius: 30px 0px 30px 30px;
                                    box-shadow: 0px 0px 15px 0px #0000001A;
                                    background-color: #6936F5;
                                    padding: 15px 30px 15px 30px;

                                    p.user-message {
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #ffff;
                                        font-weight: 400;
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }

                    .send-message-sec {
                        display: block;
                        margin-top: 50px;

                        @media (max-width: 992px) {
                            margin-top: 20px;
                        }

                        .send-message {
                            width: 100%;
                            position: relative;

                            input {
                                font-size: 14px;
                                line-height: 22px;
                                color: #656565;
                                font-weight: 400;
                                background-color: #fff;
                                border: 1px solid #E6E6E6;
                                border-radius: 10px;
                                width: 100%;
                                min-height: 55px;
                                padding: 15px 40px 15px 20px;
                                outline: none;

                                &::placeholder {
                                    color: #656565;
                                }
                            }

                            .send-icon {
                                height: 35px;
                                width: 35px;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #D9D9D9;
                                position: absolute;
                                bottom: 10px;
                                right: 10px;
                            
                                a {
                                    line-height: 1;
                                    text-decoration: none;
                                    opacity: 0.5;  // Default opacity when inactive
                                    pointer-events: none;  // Disable click when inactive
                                    transition: opacity 0.3s;
                            
                                    &.active {
                                        opacity: 1;  // Full opacity when active
                                        pointer-events: auto;  // Enable click when active
                                    }
                                    
                                    img {
                                        height: auto;
                                        width: auto;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}